// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
import $ from 'jquery';

Rails.start();
// Turbolinks.start()
// ActiveStorage.start()

// document.addEventListener("DOMContentLoaded", () => {
//   // The default of 500ms is too long and
//   // users can lose the causal link between clicking a link
//   // and seeing the browser respond
//   Turbolinks.setProgressBarDelay(100)
// })

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true);
// const imagePath = (name) => images(name, true);

// for jQuery (needed for AAP analytics)
window.jQuery = $;
window.$ = $;
